import { ref, onMounted, onUnmounted } from 'vue';

export const useDeviceDetection = (callback = () => {}) => {
  const isMobileView = ref(false);

  const calculateWidth = () => {
    isMobileView.value = window.innerWidth < 768;
  };

  const calculate = () => {
    calculateWidth();
    callback();
  };

  onMounted(() => {
    calculate();
    window.addEventListener('resize', calculate);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', calculate);
  });

  return { isMobileView };
};
